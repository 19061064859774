import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import code from "~/assets/gif/code2.gif";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            aboutme
          }
    }
`;

const MainText = styled.p`
    font-size: 3em;
    color: #d6d4d4;
    font-family: "Roboto";
    font-weight: 700;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 4.25rem;
    padding: 0 0 0 4rem;
    @media (max-width: 1250px) {
      padding: 0;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
    background-color: #003141;
`;
const Card = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  background-color: #fff;
  padding: 3rem;
  font-family: "Roboto";
  line-height: 1.2rem;
  letter-spacing: 0.5px;
  @media(max-width: 900px) {
    width: auto;
  }
  
`;
const Header = styled.div`
  font-size: 2rem;
  color: black;
  margin:0 auto;
  @media(max-width: 900px) {
  width: 95%;
  margin: 0;
  }
`;
const Body = styled.div`
color: black;
width: 60%;
margin:0 auto;
line-height: 1.5rem;
letter-spacing: 2px;
padding-bottom: 4rem;
@media(max-width: 900px) {
  padding-bottom: 0;
  width: 95%;
  margin: 0;
  }
`;
const Img = styled.img`
position: relative;
  width: 50%;
  height: 50%;
  @media(max-width: 900px) {
    width:100%;
    height: 100%;
  }
`;

const AboutPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();

  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Saba Hossein - About"
        description="Technical Project Manager with over 9 years of experience and with a large set of supporting skills. Experienced in Typescript, Javascript, React, Gatsby, Adobe XD, UX/UI Design, Node.js, HTML, CSS, Styled Components, React Spring, AWS, Imgix, HeadLess CMS, and many more."
        keywords={["saba"]}
        url={props.location.pathname}
      />
      <Container>
         <Card>
            <Header>About Me</Header>
            <Body>Accomplished technologist and Agile project manager with over 10+ years of experience in managing complex infrastructures and leading cross-functional teams. Proven expertise in driving innovation within mobile technologies and ecosystem development, as well as in restaurant management systems. Adept at developing product strategies, streamlining processes, and enhancing organizational efficiency. Known for dynamic leadership, I excel in adapting to evolving project landscapes and consistently leading teams to surpass client expectations. Demonstrated ability to reduce operational costs, optimize user experiences, and deliver high-quality software solutions.</Body>
         </Card>
         <Img src={code}>
         </Img>
      </Container>
    </>
  )
}

export default AboutPage
